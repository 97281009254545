import React from "react";
import { navigate } from "gatsby";
import styled, { css } from "styled-components";

const responsive = css`
  @media (max-width: 767px) {
    ${p => (p.desktop ? "display: none" : "")};
  }

  @media (min-width: 768px) {
    ${p => (p.mobile ? "display: none" : "")};
  }
`;

const Wrapper = styled.nav`
  position: absolute;
  top: 70px;
  left: 0;
  right: 0;
  height: calc(100vh - 70px);
  background: #005bea linear-gradient(to right, #005bea, #00c6fb); // IE
  background: var(--category-main-color) var(--category-gradient);
  display: ${p => (p.open ? "block" : "none")};
  border-top: 1px solid rgba(255, 255, 255, 0.5);
  overflow: auto;
`;

const Grid = styled.div`
  @media (min-width: 768px) {
    font-size: 14px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.5);
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(230px, 1fr));
    grid-auto-rows: 0fr;
    grid-gap: 20px;
    padding: 40px calc((100vw - var(--large-width)) / 2);
  }
`;

const GridTitle = styled.a`
  grid-column: 1 / -1;
  padding: 15px;
  font-weight: bold;
  color: #fff;
  font-size: 50px;
`;

const MenuItem = styled.a`
  color: #fff;
  display: block;
  padding: 4px 15px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  cursor: pointer;
  position: relative;
  text-align: ${p => (!p.back ? "left" : "right")};
  ${responsive};

  @media (max-width: 767px) {
    min-height: 72px;
    padding: 15px;
  }

  &:hover {
    background-color: rgba(255, 255, 255, 0.2);
  }

  &::after {
    content: "";
    display: ${p => (p.arrow ? "block" : "none")};
    position: absolute;
    ${p => (p.back ? "left" : "right")}: 20px;
    width: 12px;
    height: 12px;
    top: 50%;
    transform: translateY(-50%) rotate(${p => (p.back ? "135deg" : "-45deg")});
    border-right: 4px solid white;
    border-bottom: 4px solid white;
  }
`;

const Menu = styled.div`
  transition: all ease-in-out 200ms;
  ${responsive};

  @media (max-width: 767px) {
    transform: translateX(${p => `${p.pos * 110}%`});
    width: 100%;
    position: absolute;
  }
`;

const MenuTitle = styled.div`
  @media (max-width: 767px) {
    font-size: 27px;
    font-weight: bold;
  }
`;

const MenuTitleBig = styled(MenuTitle)`
  font-size: 16px;
  font-weight: bold;
  line-height: 2;
`;

const MenuTitleMain = styled(MenuTitle)`
  font-size: 20px;
  font-weight: bold;
  line-height: 1.2;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const MenuSubTitle = styled.div`
  font-size: 12px;
  ${responsive};
`;

class OverlayMenu extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      menu: props.category && props.category.slug
    };
  }

  goTo(menu) {
    if (!menu) {
      this.setState({ menu });
    } else if (menu.subcategories && menu.subcategories.length) {
      this.setState({ menu: menu.slug });
    } else if (menu.parent) {
      this.props.toggleMenu();
      navigate(`/${menu.parent.slug}/${menu.slug}/`);
    } else {
      this.props.toggleMenu();
      navigate(`/${menu.slug}/`);
    }
  }

  render() {
    const { menu } = this.state;
    const { open, category, categories = [] } = this.props;

    return (
      <Wrapper open={open}>
        <Menu desktop>
          <Grid>
            {category && <GridTitle>{category.title}</GridTitle>}
            {(!category
              ? categories
                  .sort((a, b) => a.node.position - b.node.position)
                  .map(e => e.node)
              : categories.find(e => e.node.slug === category.slug).node
                  .subcategories
            )
              .sort((a, b) => a.position - b.position)
              .map(c => (
                <MenuItem
                  onClick={() =>
                    this.goTo({
                      slug: c.slug,
                      title: c.title,
                      parent: category
                    })
                  }
                  key={c.slug}
                >
                  <MenuTitleMain>{c.title}</MenuTitleMain>
                  {c.subcategories && c.subcategories.length ? (
                    <MenuSubTitle>
                      {c.subcategories.length} categorie
                    </MenuSubTitle>
                  ) : null}
                </MenuItem>
              ))}
          </Grid>
        </Menu>
        <Menu pos={menu && -1} mobile>
          {categories
            .sort((a, b) => a.node.position - b.node.position)
            .map(c => (
              <MenuItem
                onClick={() => this.goTo(c.node)}
                key={c.node.slug}
                arrow
              >
                <MenuTitle>{c.node.title}</MenuTitle>
                {c.node.subcategories.length ? (
                  <MenuSubTitle>
                    {c.node.subcategories.length} categorie
                  </MenuSubTitle>
                ) : null}
              </MenuItem>
            ))}
        </Menu>
        <Grid>
          {categories.map(c => (
            <Menu
              className={`${menu} ${c.node.slug}`}
              pos={menu === c.node.slug ? 0 : 1}
              key={c.node.slug}
            >
              <MenuItem
                onClick={() =>
                  this.goTo({
                    slug: c.node.slug,
                    title: c.node.title
                  })
                }
                desktop
              >
                <MenuTitleBig>{c.node.title}</MenuTitleBig>
              </MenuItem>
              <MenuItem mobile back arrow onClick={() => this.goTo()}>
                <MenuTitle>Tutte le categorie</MenuTitle>
              </MenuItem>
              {c.node.subcategories.map(s => (
                <MenuItem
                  onClick={() => this.goTo({ ...s, parent: c.node })}
                  key={s.slug}
                >
                  <MenuTitle>{s.title}</MenuTitle>
                  {/* <MenuSubTitle>subtitle</MenuSubTitle> */}
                </MenuItem>
              ))}
            </Menu>
          ))}
        </Grid>
      </Wrapper>
    );
  }
}

export default OverlayMenu;
