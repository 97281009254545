import config from "../data/SiteConfig";

export function getImageMaxWidthUrl(id, size = 960) {
  return `https://scontent.ccdn.cloud/image/vivigratis/${id}/maxw-${size}.jpg`;
}

export function getImageUrl(id, w = 1200, h = 630) {
  return `https://scontent.ccdn.cloud/image/vivigratis/${id}/${w}x${h}.jpg`;
}

export function getGifUrl(id) {
  return `https://scontent.ccdn.cloud/image/vivigratis/${id}.gif`;
}

export function getPriceLabel(price, currency = "EUR") {
  return price.toLocaleString("it-IT", {
    style: "currency",
    currency
  });
}

export const requestAnimFrame =
  typeof window !== "undefined" ?
  (() =>
    window.requestAnimationFrame ||
    window.webkitRequestAnimationFrame ||
    window.mozRequestAnimationFrame ||
    function cb(callback) {
      window.setTimeout(callback, 1000 / 60);
    })() :
  () => {};

export function getGesturePointFromEvent(evt) {
  const point = {};
  if (evt.targetTouches) {
    point.x = evt.targetTouches[0].clientX;
    point.y = evt.targetTouches[0].clientY;
  } else {
    point.x = evt.clientX;
    point.y = evt.clientY;
  }
  return point;
}

export function preventEvent(evt) {
  evt.preventDefault();
  evt.stopPropagation();
}

export function logEvent(options) {
  if (window.ga && !window.isPreview) {
    window.ga(`send`, `event`, {
      eventCategory: options.category || `Custom Event`,
      eventAction: options.action || `click`,
      eventLabel: options.label || ""
    });
  }
}

export function getTheme(slug) {
  return config.themes[slug] || config.themes.default;
}

export function getAbsUrl() {
  return `${config.siteUrl}${
    config.pathPrefix === "/" ? "" : config.pathPrefix
  }`;
}
export function getRedirectUrl(id) {
  return `/redirect/${id}`;
}