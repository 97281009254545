import React from "react";
import styled, { css } from "styled-components";

const Wrapper = styled.nav`
  background-color: #f8f8f8;
  width: 100%;
  z-index: 80;
  height: 40px;
  font-size: 16px;
  padding: 0 calc((100vw - var(--large-width)) / 2);
  box-shadow: 0px 1px 2px 0 rgba(0, 0, 0, 0.15);
  transition: all ease-in-out 200ms;
  margin-top: -40px;
  transform: translateY(100%);

  ${p =>
    p.reading &&
    css`
      transform: translateY(0);
      opacity: 0;
      pointer-events: none;
    `};

  @media (max-width: 767px) {
    height: 1px;
    overflow: hidden;
    margin-top: -1px;
  }
`;

const Item = styled.div`
  position: relative;
  display: inline-block;

  &::after {
    content: "";
    display: ${p => (p.active ? "block" : "none")};
    position: absolute;
    background: #005bea linear-gradient(to right, #005bea, #00c6fb); // IE
    background: var(--category-main-color) var(--category-gradient);
    bottom: 0;
    height: 4px;
    width: 100%;
    left: 0;
  }
`;

const MainLink = styled.a`
  height: 40px;
  display: inline-flex;
  align-items: center;
  padding: 10px 15px;
  color: ${p => (p.active ? "var(--category-main-color)" : "currentColor")};
  text-decoration: none;
`;

const Inner = styled.div`
  position: absolute;
  top: calc(100% - 4px);
  z-index: 100;
  left: 0;
  background-color: #fff;
  color: #000;
  font-size: 14px;
  box-shadow: 2.5px 4.3px 5px 0 rgba(0, 0, 0, 0.2);
  padding-top: 4px;
  min-width: 100%;

  ${Item}:not(:hover) & {
    display: none;
  }

  &::after {
    content: "";
    position: absolute;
    background: #005bea linear-gradient(to right, #005bea, #00c6fb); // IE
    background: var(--category-main-color) var(--category-gradient);
    top: 0;
    height: 4px;
    width: 100%;
    left: 0;
  }
`;

const Link = styled.a`
  height: 40px;
  display: flex;
  align-items: center;
  padding: 10px;
  white-space: nowrap;
  color: inherit;
  text-decoration: none;
  color: ${p => (p.active ? "var(--category-main-color)" : "currentColor")};
  background-color: ${p => (p.active ? "#f8f8f8" : "transparent")};

  &:hover {
    background-color: #f8f8f8;
  }
`;

class SubMenu extends React.Component {
  render() {
    const { reading, category, subcategory, categories = [] } = this.props;

    return (
      <Wrapper reading={reading}>
        {categories.sort((a, b) => a.node.position - b.node.position).map(c => (
          <Item
            key={c.node.slug}
            className={c.node.slug}
            active={category && category.slug === c.node.slug}
          >
            <MainLink
              href={`/${c.node.slug}/`}
              active={category && category.slug === c.node.slug}
            >
              {c.node.title}
            </MainLink>
            {c.node.subcategories && (
              <Inner>
                {c.node.subcategories.map(s => (
                  <Link
                    key={s.slug}
                    href={`/${c.node.slug}/${s.slug}/`}
                    active={subcategory && subcategory.slug === s.slug}
                  >
                    {s.title}
                  </Link>
                ))}
              </Inner>
            )}
          </Item>
        ))}
      </Wrapper>
    );
  }
}

export default SubMenu;
