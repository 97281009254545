import styled from "styled-components";

const minCellWidthPx = 280;
const cellGapPx = 30;

const Grid = styled.section`
  --min-cell-width: ${minCellWidthPx}px;
  --cell-gap: 20px;

  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(var(--min-cell-width), 1fr));
  grid-gap: var(--cell-gap);
  max-width: 1200px; // IE
  max-width: var(--large-width);
  margin: auto;
  padding: var(--cell-gap);
  width: 100%;

  @media (min-width: ${minCellWidthPx * 2 + cellGapPx * 2}px) {
    --cell-gap: ${cellGapPx}px;
  }
`;

export default Grid;
