import React from "react";
import styled from "styled-components";
import MainBar from "./MainBar";
import SubMenu from "./SubMenu";
import OverlayMenu from "./OverlayMenu";

const Wrapper = styled.div`
  position: ${p => (p.open ? "fixed" : "sticky")};
  top: 0;
  z-index: 100;
  margin-bottom: 80px;
  box-shadow: 0px 1px 2px 0 rgba(0, 0, 0, 0.15);

  @media (max-width: 767px) {
    margin-bottom: 40px;
  }
`;

class Topbar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      reading: false,
      open: false
    };
    this.last_known_scroll_offset = 0;
    this.last_known_scroll_position = 0;
    this.ticking = false;
    this.toggleMenu = this.toggleMenu.bind(this);
    this.onKeyUp = this.onKeyUp.bind(this);
    this.onScroll = this.onScroll.bind(this);
  }

  componentDidMount() {
    window.addEventListener("keyup", this.onKeyUp);
    window.addEventListener("scroll", this.onScroll);
  }

  componentWillUnmount() {
    window.removeEventListener("keyup", this.onKeyUp);
    window.removeEventListener("scroll", this.onScroll);
  }

  onKeyUp(e) {
    switch (e.key) {
      case "Escape":
      case "m":
        this.toggleMenu();
        break;

      default:
        break;
    }
  }

  onScroll() {
    const offset = window.scrollY - this.last_known_scroll_position;
    this.last_known_scroll_position = window.scrollY;
    this.last_known_scroll_offset =
      Math.sign(offset) === Math.sign(this.last_known_scroll_offset)
        ? this.last_known_scroll_offset + offset
        : offset;

    if (!this.ticking) {
      window.requestAnimationFrame(() => {
        if (this.last_known_scroll_offset < -150)
          this.setState({ reading: false });
        else if (this.last_known_scroll_offset > 150)
          this.setState({ reading: true });
        this.ticking = false;
      });
      this.ticking = true;
    }
  }

  toggleMenu() {
    const { open } = this.state;
    document.body.classList.toggle("has-menu-open", !open);
    this.setState({ open: !open, reading: false });
  }

  render() {
    const { open, reading } = this.state;
    const {
      title,
      category,
      subcategory,
      categories = [],
      sharePath,
      shareTitle,
      shareImageId
    } = this.props;

    return (
      <Wrapper className={category ? category.slug : ""}>
        <MainBar
          title={title}
          category={category}
          open={open}
          reading={reading}
          toggleMenu={this.toggleMenu}
          sharePath={sharePath}
          shareTitle={shareTitle}
          shareImageId={shareImageId}
        />
        <SubMenu
          reading={reading}
          category={category}
          subcategory={subcategory}
          categories={categories}
        />
        <OverlayMenu
          open={open}
          category={category}
          categories={categories}
          toggleMenu={this.toggleMenu}
        />
      </Wrapper>
    );
  }
}

export default Topbar;
