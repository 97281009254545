import React from "react";
import { Link } from "gatsby";
import styled, { css } from "styled-components";
import { getImageUrl } from "../../utilities";

const minCellWidthPx = 280;
const cellGapPx = 30;

const ellipsis = css`
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  display: -webkit-box;
  overflow: hidden;
`;

const Wrapper = styled(Link)`
  --tile-height: 120px;

  float: left; // IE
  width: 99%; // IE
  margin: 20px 0.5% 80px;

  @media (min-width: 620px) {
    width: 49%; // IE
  }

  @media (min-width: 767px) {
    width: 32.333%; // IE
  }

  @supports (--var: 0) {
    width: 100%;
  }

  background: var(--category-gradient);
  font-size: 17px;
  line-height: 1.35;
  margin: 0 0 calc(var(--tile-height) / 2);
  position: relative;
`;

const BigWrapper = styled(Wrapper)`
  @media (min-width: ${minCellWidthPx * 2 + cellGapPx * 2}px) {
    --tile-height: 250px;

    font-size: 40px;
    grid-column: 1 / -1;
    width: 100%;
  }
`;

const Cover = styled.div`
  height: 0;
  overflow: hidden;
  width: 100%;
  padding-bottom: 56.25%;
  position: relative;
  display: block;

  img {
    width: 100%;
  }
`;

const Header = styled.div`
  align-items: center;
  background-color: #fff;
  border: 1px solid #f0f0f0;
  bottom: 0;
  box-sizing: border-box;
  color: #000;
  display: flex;
  flex-direction: column;
  min-height: var(--tile-height);
  justify-content: center;
  left: 10%;
  padding: 20px 20px 10px;
  position: absolute;
  right: 10%;
  text-align: center;
  transform: translateY(50%);
  transition: all ease 200ms;

  ${Wrapper}:hover & {
    box-shadow: 0 3px 15px rgba(0, 0, 0, 0.15);
    transform: translateY(calc(50% - 5px));
  }

  &::after {
    background: #005bea linear-gradient(to right, #005bea, #00c6fb); // IE
    background: var(--category-main-color) var(--category-gradient);
    bottom: -1px;
    content: "";
    height: 4px;
    left: -1px;
    position: absolute;
    right: -1px;
    transform-origin: center bottom;
    transition: all ease 200ms;

    ${Wrapper}:hover & {
      transform: scaleY(1.5);
    }
  }
`;

const Category = styled.div`
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  text-align: center;
  font-weight: bold;
  text-transform: lowercase;
  font-size: 14px;
  padding: 5px 10px;
  white-space: nowrap;

  @supports (mix-blend-mode: lighten) {
    background: #fff;

    &::after {
      content: "";
      position: absolute;
      top: 1px;
      left: 1px;
      right: 1px;
      bottom: 1px;
      mix-blend-mode: lighten;
      background: var(--category-main-color) var(--category-gradient);
    }
  }
`;

const Title = styled.h3`
  ${ellipsis};
  font-family: var(--font-serif);
  font-size: 1em;
  height: 2.6em;
  margin: 20px 0;
  max-height: 2.8em;
  max-width: 100%;

  @media (min-width: ${minCellWidthPx * 2 + cellGapPx * 2}px) {
    ${BigWrapper} & {
      height: auto;
    }
  }
`;

const Excerpt = styled.p`
  ${ellipsis};
  font-size: 0.5em;
  font-weight: normal;
  margin: 0;
  max-height: 2.8em;
  max-width: 100%;

  @media (max-width: ${minCellWidthPx * 2 + cellGapPx * 2 - 1}px) {
    display: none;
  }
`;

const CoverImg = ({ coverId, alt, title }) => (
  <Cover>
    <img
      alt={alt}
      title={title}
      srcSet={`${getImageUrl(coverId, 360, Math.round(360 * 0.5625))} 360w,
        ${getImageUrl(coverId, 580, Math.round(580 * 0.5625))} 580w,
        ${getImageUrl(coverId, 720, Math.round(720 * 0.5625))} 720w`}
      src={getImageUrl(coverId, 580, Math.round(580 * 0.5625))}
      sizes="(max-width: 620px) calc(100vw - 40px), (max-width: 650px) calc(100vw - 60px), (max-width: 960px) calc(50vw - 45px), (max-width: 1200px) calc(33.333% - 40px), 360px"
    />
  </Cover>
);

const BigCoverImg = ({ coverId, alt, title }) => (
  <Cover>
    <img
      alt={alt}
      title={title}
      srcSet={`${getImageUrl(coverId, 320, Math.round(320 * 0.5625))} 320w,
        ${getImageUrl(coverId, 768, Math.round(768 * 0.5625))} 768w,
        ${getImageUrl(coverId, 1140, Math.round(1140 * 0.5625))} 1140w,
        ${getImageUrl(coverId, 1710, Math.round(1710 * 0.5625))} 1710w,
        ${getImageUrl(coverId, 1920, Math.round(1920 * 0.5625))} 1920w`}
      src={getImageUrl(coverId, 1140, Math.round(1140 * 0.5625))}
      sizes="(max-width: 620px) calc(100vw - 40px), (max-width: 1200px) calc(100vw - 60px), 1140px"
    />
  </Cover>
);

const Tile = ({
  title,
  slug,
  category,
  categorySlug,
  coverId,
  coverAlt,
  coverTitle,
  onClick
}) => (
  <Wrapper to={`/${slug}/`} className={categorySlug} onClick={onClick}>
    <CoverImg coverId={coverId} alt={coverAlt} title={coverTitle} />
    <Header>
      <Category>{category}</Category>
      <Title>{title}</Title>
    </Header>
  </Wrapper>
);

const BigTile = ({
  title,
  slug,
  category,
  categorySlug,
  coverId,
  coverAlt,
  coverTitle,
  abstract,
  onClick
}) => (
  <BigWrapper to={`/${slug}/`} className={categorySlug} onClick={onClick}>
    <BigCoverImg coverId={coverId} alt={coverAlt} title={coverTitle} />
    <Header>
      <Category>{category}</Category>
      <Title>{title}</Title>
      <Excerpt>{abstract}</Excerpt>
    </Header>
  </BigWrapper>
);

export default Tile;
export { BigTile };
