import React from "react";
import styled, { css } from "styled-components";
import {
  FacebookShareButton,
  TwitterShareButton,
  PinterestShareButton,
  WhatsappShareButton,
  TelegramShareButton
} from "react-share";
import { getImageUrl, getAbsUrl } from "../../utilities";

const responsive = css`
  @media (max-width: 767px) {
    ${p => (p.desktop ? "display: none" : "")};
  }

  @media (min-width: 768px) {
    ${p => (p.mobile ? "display: none" : "")};
  }
`;

const Wrapper = styled.div`
  display: flex;
  z-index: 100;
  align-items: center;
  flex-direction: column;
  position: relative;
  width: 100%;
  background: #005bea linear-gradient(to right, #005bea, #00c6fb); // IE
  background: ${p =>
    p.open ? "var(--category-main-color) var(--category-gradient)" : "#fff"};
`;

const Bar = styled.div`
  height: 70px;
  padding-top: 2px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100vw;
  max-width: var(--large-width);
  position: relative;
`;

const InnerBar = styled.div`
  position: absolute;
  background: #005bea linear-gradient(to right, #005bea, #00c6fb); // IE
  background: var(--category-main-color) var(--category-gradient);
  color: #fff;
  display: flex;
  align-items: center;
  flex-direction: column;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  transition: all ease-in-out 200ms;
  transform: translateY(
    ${p => (p.reading ? "translateY(0)" : "calc(-100% + 4px)")}
  );
`;

const Logo = styled.a`
  height: 35px;
  width: 140px;
  display: block;
  flex: 1 0 auto;
  background-clip: content-box;
  background: #005bea linear-gradient(to right, #005bea, #00c6fb); // IE
  background: ${p =>
    p.open
      ? "transparent"
      : "var(--category-main-color) var(--category-gradient)"};
`;

const Img = styled.img`
  height: 35px;
  width: 140px;
  display: block;
  transform: scale(1.02);

  ${p =>
    !p.open &&
    css`
      mix-blend-mode: lighten;
      filter: invert(1);
      background: #000;
    `};
`;

const Side = styled.div`
  flex: 50% 1 1;
  display: flex;
  align-items: center;

  &:last-child {
    justify-content: flex-end;
  }
`;

const MenuButton = styled.a`
  display: inline-block;
  vertical-align: bottom;
  margin: 0 10px;
  z-index: 1000;
  cursor: pointer;
`;

const MenuIcon = styled.div`
  background-color: #005bea; // IE
  background-color: ${p => (p.reverse ? "#fff" : "var(--category-main-color)")};
  width: 50px;
  padding: 23px 10px;
  height: 50px;
  background-clip: content-box;
  position: relative;
  transition: all ease-in-out 200ms;
  transform: rotate(-45deg);

  &::before,
  &::after {
    position: absolute;
    width: 20px;
    left: 15px;
    background-color: #005bea; // IE
    background: ${p => (p.reverse ? "#fff" : "var(--category-main-color)")};
    height: 4px;
    content: "";
    transition: all ease-in-out 200ms;
    transform: rotate(${p => (p.opened ? "90deg" : "0deg")});
  }

  &::before {
    top: 12px;
  }

  &::after {
    bottom: 12px;
  }
`;

const PageTitle = styled.div`
  margin-left: 80px;

  @media (max-width: 767px) {
    display: none;
  }

  a {
    color: inherit;
    text-decoration: none;
  }
`;

const IconTray = styled.div`
  padding: 0 10px;
  color: #fff;
  display: flex;
  align-items: center;
  position: relative;
  font-size: 14px;
  background: ${p =>
    p.reverse ? "transparent" : "var(--category-secondary-color)"};
  background-clip: content-box;

  span {
    display: inline-block;
    margin-right: 10px;
  }
`;

const TopbarIcon = styled.a`
  height: 40px;
  width: 40px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background: white;
  cursor: pointer;
  mix-blend-mode: lighten;
  filter: invert(${p => (p.reverse ? 1 : 0)});
  ${responsive};

  img {
    filter: brightness(0);
    height: 24px;
  }
`;

// const SearchInput = styled.div`
//   position: absolute;
//   right: 100%;
//   top: 0;
//   padding: 0;
//   width: ${p => (p.open ? "200px" : "1px")};
//   overflow: hidden;
//   box-sizing: border-box;
//   transition: all ease 200ms;

//   input {
//     border: 1px solid
//       ${p => (p.reverse ? "#fff" : "var(--category-secondary-color)")};
//     width: 100%;
//     height: 40px;
//     border-radius: 5px;
//     padding: 5px;
//     font-size: 14px;
//   }
// `;

// class SearchBar extends React.Component {
//   constructor(props) {
//     super(props);
//     this.state = {
//       search: false
//     };
//     this.toggleSearch = this.toggleSearch.bind(this);
//   }

//   toggleSearch() {
//     this.setState({
//       search: !this.state.search
//     });
//     if (!this.state.search) this.searchInput.focus();
//   }

//   render() {
//     const { search } = this.state;
//     const { reverse } = this.props;

//     return (
//       <IconTray reverse={reverse} style={{ display: "block" }}>
//         <TopbarIcon reverse={reverse} onClick={this.toggleSearch}>
//           <img src="/images/lente.svg" alt="Search" />
//         </TopbarIcon>
//         <SearchInput reverse={reverse} open={search}>
//           <input
//             type="text"
//             ref={e => {
//               this.searchInput = e;
//             }}
//           />
//         </SearchInput>
//       </IconTray>
//     );
//   }
// }

class MainBar extends React.Component {
  render() {
    const {
      title,
      category,
      open,
      reading,
      toggleMenu,
      sharePath,
      shareTitle,
      shareImageId
    } = this.props;

    const sharePageUrl = `${getAbsUrl()}${sharePath || ""}`;
    const shareImageUrl = shareImageId ? getImageUrl(shareImageId) : "";

    const TelegramButton = TopbarIcon.withComponent(TelegramShareButton);
    const WhatsappButton = TopbarIcon.withComponent(WhatsappShareButton);
    const FacebookButton = TopbarIcon.withComponent(FacebookShareButton);
    const TwitterButton = TopbarIcon.withComponent(TwitterShareButton);
    const PinterestButton = TopbarIcon.withComponent(PinterestShareButton);

    return (
      <Wrapper open={open}>
        <Bar>
          <Side>
            <MenuButton onClick={toggleMenu} open={open}>
              <MenuIcon reverse={open || reading} opened={open} />
            </MenuButton>
          </Side>
          <Logo href="/" open={open} onClick={() => open && toggleMenu()}>
            <Img
              src="/logos/logo.svg"
              width="140"
              height="35"
              alt="Vivigratis logo"
              open={open}
            />
          </Logo>
          <Side>
            {
              <IconTray reverse={open}>
                <TopbarIcon
                  href="https://www.facebook.com/vivigratis/"
                  target="_blank"
                  rel="noopener noreferrer"
                  title="Segui la nostra pagina su Facebook"
                  reverse={open}
                  desktop
                >
                  <img src="/images/facebook.svg" alt="Facebook logo" />
                </TopbarIcon>
                <TopbarIcon
                  href="https://www.youtube.com/channel/UClrP8wsIyBszzMHyhPuPXzQ?sub_confirmation=1"
                  target="_blank"
                  rel="noopener noreferrer"
                  title="Iscriviti al canale YouTube"
                  reverse={open}
                  desktop
                >
                  <img src="/images/youtube.svg" alt="Youtube logo" />
                </TopbarIcon>
                <TopbarIcon
                  href="https://flipboard.com/@vivigratis"
                  target="_blank"
                  rel="noopener noreferrer"
                  title="Segui i magazine di Vivigratis su Flipboard"
                  reverse={open}
                  desktop
                >
                  <img src="/images/flipboard.svg" alt="Flipboard logo" />
                </TopbarIcon>
              </IconTray> /* <SearchBar reverse={open} /> */
            }
          </Side>
        </Bar>
        <InnerBar reading={reading}>
          <Bar>
            <Side>
              {category && (
                <PageTitle>
                  <a href={`/${category.slug}/`}>
                    <b>{category.title}</b>
                  </a>
                  {title && ` • ${title}`}
                </PageTitle>
              )}
            </Side>
            <Side>
              <IconTray reverse>
                <span>Condividi</span>
                <WhatsappButton
                  reverse
                  url={sharePageUrl}
                  title={shareTitle}
                  mobile
                >
                  <img src="/images/whatsapp.svg" alt="Whatsapp logo" />
                </WhatsappButton>
                <TelegramButton
                  reverse
                  url={sharePageUrl}
                  title={shareTitle}
                  mobile
                >
                  <img src="/images/telegram.svg" alt="Telegram logo" />
                </TelegramButton>
                <FacebookButton reverse url={sharePageUrl}>
                  <img src="/images/facebook.svg" alt="Facebook logo" />
                </FacebookButton>
                {shareImageUrl && (
                  <PinterestButton
                    reverse
                    url={sharePageUrl}
                    media={shareImageUrl}
                    description={shareTitle}
                  >
                    <img src="/images/pinterest.svg" alt="Pinterest logo" />
                  </PinterestButton>
                )}
                <TwitterButton reverse url={sharePageUrl} title={shareTitle}>
                  <img src="/images/twitter.svg" alt="Twitter logo" />
                </TwitterButton>
              </IconTray>
            </Side>
          </Bar>
        </InnerBar>
      </Wrapper>
    );
  }
}

export default MainBar;
