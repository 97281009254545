import React, { Component } from "react";
import styled from "styled-components";
import CookieBox from "../CookieBox/CookieBox";
import Grid from "../Grid/Grid";
import Tile from "../Tile/Tile";
import { H3 } from "../Typo/Typo";
import { logEvent } from "../../utilities";

const Wrapper = styled.footer`
  background: #f8f8f8;
  padding: 0;
  margin-top: calc(var(--gutter) * 2);
  clear: both;
`;

const Related = styled.aside`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: var(--gutter) 0;
`;

const Title = styled(H3)`
  border: 0;
  margin: var(--gutter) auto;
  padding: 0;
`.withComponent("h2");

const A = styled.a`
  color: var(--category-main-color);
`;

const Copy = styled.div`
  padding: var(--gutter);
  text-align: center;
  font-size: 0.7em;
  color: #999;
`;

function logRelated(title) {
  logEvent({
    category: "related",
    action: "click",
    label: title
  });
}

class Footer extends Component {
  render() {
    return (
      <Wrapper>
        {this.props.related && this.props.related.length ? (
          <Related>
            <Title>Articoli che potrebbero interessarti</Title>
            <Grid>
              {this.props.related.map(el => (
                <Tile
                  key={el.slug}
                  slug={el.slug}
                  title={el.title}
                  category={el.category.title}
                  categorySlug={el.category.slug}
                  coverId={el.coverId}
                  coverAlt={el.coverAlt}
                  coverTitle={el.coverTitle}
                  onClick={() => logRelated(el.title)}
                />
              ))}
            </Grid>
          </Related>
        ) : null}
        <Copy>
          ® 2018 Vivigratis è un marchio registrato © Tutti i diritti riservati.
          &nbsp;
          <A href="/privacy-policy/">Privacy policy</A>.
        </Copy>
        <CookieBox />
      </Wrapper>
    );
  }
}

export default Footer;
