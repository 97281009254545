import React, { Component } from "react";
import styled from "styled-components";

const Wrapper = styled.div`
  position: fixed;
  bottom: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 1);
  color: white;
  padding: calc(var(--gutter) / 2);
  width: 100%;
  line-height: 1.2;
  transform: translateY(${p => (p.visible ? 0 : "20px")});
  pointer-events: ${p => (p.visible ? "all" : "none")};
  opacity: ${p => (p.visible ? 1 : 0)};
  transition: all ease 500ms;

  a {
    color: inherit;
  }

  @media (min-width: 768px) {
    width: 500px;
    bottom: calc(var(--gutter) / 2);
    right: calc(var(--gutter) / 2);
  }
`;

const Button = styled.button`
  background-color: #ff6b00; // IE
  background-color: var(--color-cta);
  border-radius: 5px;
  color: #fff;
  display: block;
  border: 0;
  margin-top: calc(var(--gutter) / 2);
  cursor: pointer;
  font-size: 1em;
  font-weight: bold;
  line-height: 1.25;
  padding: calc(var(--gutter) / 2);
  text-align: center;
  text-decoration: none;
  transition: all ease 200ms;
  width: 120px;
  float: right;

  &:hover {
    opacity: 0.8;
  }
`;

class CookieBox extends Component {
  constructor(props) {
    super(props);
    this.COOKIE_NAME = "cookieAccepted";
    this.COOKIE_VERSION = "20180412";

    this.state = {
      accepted: true
    };
    this.onScroll = this.onScroll.bind(this);
    this.createBox = this.createBox.bind(this);
    this.accept = this.accept.bind(this);
  }

  componentDidMount() {
    setTimeout(this.createBox, 500);
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.onScroll);
  }

  onScroll() {
    window.removeEventListener("scroll", this.onScroll);
    setTimeout(this.accept, 500);
  }

  createBox() {
    const accepted =
      !(typeof window !== "undefined") ||
      window.localStorage.getItem(this.COOKIE_NAME) === this.COOKIE_VERSION;
    this.setState({ accepted });
    if (!accepted) window.addEventListener("scroll", this.onScroll, false);
  }

  accept() {
    window.removeEventListener("scroll", this.onScroll);
    window.localStorage.setItem(this.COOKIE_NAME, this.COOKIE_VERSION);
    this.setState({ accepted: true });
  }

  render() {
    return (
      <Wrapper visible={!this.state.accepted}>
        Con il tuo consenso, questo sito internet utilizza cookies di terze
        parti per migliorare la tua esperienza. Puoi conoscere di più sul nostro
        utilizzo dei cookies e su come modificare le impostazioni nella
        nostra&nbsp;<a href="/cookie-policy/">Informativa sui Cookies</a>.
        Chiudendo questo banner, cliccando in seguito o continuando a utilizzare
        il sito, acconsenti all&rsquo;utilizzo dei cookies.
        <Button onClick={this.accept}>OK</Button>
      </Wrapper>
    );
  }
}

export default CookieBox;
