import styled, { css } from "styled-components";

export const linkStyle = css`
  text-decoration: none;
  color: currentColor;
  border: 0 solid transparent;
  border-bottom: 1px solid var(--category-main-color);
  border-image: var(--category-gradient) 1;
  padding: 0.1em;

  &:hover {
    background-color: var(--category-secondary-light-color);
  }
`;

export const A = styled.a`
  ${linkStyle};
`;

export const TitleBase = css`
  border: 0 solid var(--category-main-color);
  border-image: var(--category-gradient) 1;
  font-family: var(--font-serif);
  line-height: 1.25;
  margin: 0 auto calc(var(--gutter) * 2);
  max-width: 100%;
  position: relative;
  text-align: center;
  top: -80px;
  transform: translateY(80px);
  width: calc(100vw - var(--gutter) * 2);

  &:not(:first-child) {
    margin-top: calc(var(--gutter) * 2);
  }

  ${A} {
    padding-top: 0;
    padding-bottom: 0;
  }

  .anchor {
    display: none;
  }
`;

export const H1 = styled.h1`
  ${TitleBase};
  font-style: italic;
  font-weight: bold;
  font-size: 42px;
  margin-bottom: var(--gutter);
`;

export const H2 = styled.h2`
  ${TitleBase};
  font-size: 30px;
  max-width: calc(100% - var(--gutter) * 2);
  order: 10;
  border-top-width: 1px;
  padding: var(--gutter)
    calc((100% - (var(--content-width) + var(--gutter) * 2)) / 2) 0;

  & ~ * {
    order: 10;
  }
`;

export const H3 = styled.h3`
  ${TitleBase};
  font-size: 28px;
  font-style: italic;
  font-weight: normal;
  max-width: var(--content-width);
  padding-top: var(--gutter);
  border-top-width: 1px;
`;

export const H4 = styled.h4`
  ${TitleBase};
  font-size: 28px;
  font-weight: normal;
`;

export const Accordion = css`
  cursor: pointer;
  text-align: left;
  padding-left: 40px;
  position: relative;
  margin-bottom: calc(var(--gutter) * ${p => (p.closed ? 0 : 2)});

  &::after {
    content: "";
    position: absolute;
    top: ${p => (p.closed ? "0.9em" : "1.2em")};
    left: 0.2em;
    display: block;
    width: 12px;
    height: 12px;
    transform: rotate(${p => (p.closed ? "135deg" : "-45deg")});
    border-right: 3px solid currentColor;
    border-top: 3px solid currentColor;

    @supports (--var: 0) {
      border-color: var(--category-main-color);
    }
  }

  ~ * {
    display: ${p => (p.closed ? "none" : "block")};
  }
`;

export const P = styled.p`
  max-width: calc(var(--content-width) + var(--gutter) * 2);
  margin: calc(var(--gutter) / 2) auto;
  padding: 0 var(--gutter);
  width: 100%;

  > img {
    max-width: 100%;
  }
`;

export const ListBase = css`
  max-width: var(--content-width);
  margin: calc(var(--gutter) / 2) auto;
  padding-left: var(--gutter);
  line-height: 26px;
  width: calc(100vw - var(--gutter) * 2);

  li {
    margin-bottom: calc(var(--gutter) / 2);
  }
`;

export const Ol = styled.ol`
  ${ListBase};
`;

export const Ul = styled.ul`
  ${ListBase};
`;

export const Blockquote = styled.blockquote`
  background: #005bea linear-gradient(to right, #005bea, #00c6fb); // IE
  background: var(--category-main-color) var(--category-gradient);
  color: #fff;
  line-height: 1.5;
  margin: var(--gutter);
  padding: calc(var(--gutter) / 2) var(--gutter);
  text-align: center;
  max-width: calc(100vw - var(--gutter) * 2);
  font-size: 1.25em;
`;

export const Toc = styled.nav`
  order: 5;
  border: 2px solid #005bea; // IE
  border: 2px solid var(--category-main-color);
  border-image: linear-gradient(to right, #005bea, #00c6fb) 1; // IE
  border-image: var(--category-gradient) 1;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.2);
  margin: ${p => (p.label ? 70 : 30)}px auto var(--gutter);
  max-width: calc(100% - var(--gutter) * 2);
  padding: var(--gutter);
  position: relative;
  width: calc(100vw - var(--gutter) * 2);

  @media (max-width: 767px) {
    display: none;
  }

  ul {
    list-style-type: decimal;
    padding-left: var(--gutter);

    p {
      margin: 0;
    }

    a {
      ${linkStyle};
      border-bottom: 0;
    }
  }
`;
